const getbaseUrl = () => {
    return location.protocol + '//' + location.host + location.pathname;
};

export class FormFilterClass {
    constructor(options) {
        this.conf = FormFilterClass.mergeSettings(options);
        if (!this.conf.form) {
            return false;
        }

        this.loading = false;
        this.fetchRequest = null;
        this.remain = true;
        this.window = $(window);
        this.conf.currentpage = this.conf.startpage;

        this.url = this.conf.form.getAttribute('action');
        this.baseUrl = getbaseUrl();
        this.mergeWithDataset();
        this.init();
    }

    /**
     * Overrides default settings with custom ones.
     * @param {Object} options - Optional settings object.
     * @returns {Object} - Custom FormFilterClass settings.
     */
    static mergeSettings(options) {
        const settings = {
            form: false,
            container: false,
            lazyload: false,
            startpage: 1,
            isAjax: true,
            updateState: false,
            AllowStateParams: false,
            onInit: () => {
            },
            onLazyload: () => {
            },
            onChange: () => {
            },

            /**
             *  Null or Function
             */
            processResponse: null
        };

        const userSttings = options;
        for (const attrname in userSttings) {
            settings[attrname] = userSttings[attrname];
        }

        return settings;
    }

    mergeWithDataset() {
        let dataset = this.conf.form.dataset.default;
        if (dataset) {
            dataset = JSON.parse(dataset);
            for (let item in dataset) {
                this[item] = dataset[item];
            }
        }
    }

    fetch(append, callback = null) {
        const page = this.conf.currentpage;
        const _this = this;
        const $f = $(this.conf.form);
        const data = $f.serializeArray();
        let result = null;

        if (page && page !== 1) {
            data.push({name: 'page', value: page});
        }

        if (this.fetchRequest && this.fetchRequest.readyState !== 4) {
            this.fetchRequest.abort();
        }

        this.fetchRequest = $.get(this.url, data);
        this.fetchRequest
            .then(function (r) {
                _this.setUrlWithParams(data);
                result = r;
                const $r = r.dom;
                _this.remain = r.remain;

                if (_this.conf.processResponse) {
                    _this.conf.processResponse(_this.conf.container, $r, append);
                } else {
                    if (append) {
                        const node = document.createRange().createContextualFragment($r);
                        _this.conf.container.appendChild(node);
                    } else {
                        if (!$r) {
                            _this.conf.container.innerHTML = '';
                        } else {
                            _this.conf.container.innerHTML = $r;
                        }
                    }
                }

                if (callback) {
                    callback(result);
                }
            });
    }

    start_loading() {
        this.loading = true;
        if (this.conf.loader) {
            const loader = document.getElementById(this.conf.loader);
            loader && (loader.style.display = 'block');
        }
    }

    finish_loading() {
        this.loading = false;
        if (this.conf.loader) {
            const loader = document.getElementById(this.conf.loader);
            loader && (loader.style.display = 'none');
        }
    }

    updatePage(page) {
        this.conf.currentpage = page;
        this.load();
    }

    change() {
        this.conf.currentpage = 1;
        this.load();
    }

    load() {
        this.start_loading();
        this.fetch(false, () => {
            this.finish_loading();
            this.conf.onChange.call(this);
        });
    }

    init() {
        if (this.conf.form && this.conf.container) {
            if (this.conf.loader) {
                const loader = document.getElementById(this.conf.loader);
                loader && (loader.style.display = 'none');
            }

            const $f = $(this.conf.form);

            if (this.conf.isAjax) {
                $f.on('submit', e => e.preventDefault());
            }

            $f.on('input', () => {
                if (this.conf.isAjax) {
                    this.change();
                } else {
                    $f.submit();
                }
            });

            this.conf.onInit.call(this);
        }
    }

    setUrlWithParams(data) {
        if (this.conf.updateState && this.conf.AllowStateParams) {
            const out = [];
            for (let i = 0; i < data.length; i++) {
                const obj = data[i];
                if (this.conf.AllowStateParams.indexOf(obj.name) > -1) {
                    out.push(encodeURIComponent(obj.name) + '=' + encodeURIComponent(obj.value));
                }
            }
            const fullurl = this.baseUrl + '?' + out.join('&');
            window.history.replaceState(data, 'aaa', fullurl);
        }
    }

    resetForm() {
        this.conf.form.reset();
        this.change();
        const input = this.conf.form.getElementsByTagName("input");

        if (input) {
            $(input).prop('checked', false);
        }

        const windowWidth = window.innerWidth;
        if (windowWidth > '959') {
            $('html, body').animate({
                scrollTop: $(this.conf.form).offset().top - 200
            }, 600);
        }
    }
}
