import $ from "jquery";

export default class {

    constructor(element) {
        this.element = element;
        this.title = element.querySelector('.accordion__title');
        this.content = $(element.querySelector('.accordion__body'));
        this.animating = false;
        this.init();
    }


    show() {
        this.startAnimating();
        this.element.classList.add('is-collapsed');
        this.content.slideDown(200);
        setTimeout(() => {
            this.stopAnimation();
        }, 200)
    }

    hide() {
        this.startAnimating();
        this.element.classList.remove('is-collapsed');
        this.content.slideUp(200);

        setTimeout(() => {
            this.stopAnimation();
        }, 200)
    }

    startAnimating() {
        this.animating = true;
    }

    stopAnimation() {
        this.animating = false;
    }


    init() {
        this.title.addEventListener('click', () => {
            if (!this.animating) {
                this.element.classList.contains('is-collapsed') ? this.hide() : this.show()
            }
        });

        if ($(window).width() <= 960) {
            this.hide()
        }
    }
}
