import anime from 'animejs';

export default class {
    constructor() {
        this.open = false;
        this.isAnimate = false;
        this.menu = document.querySelector('.menu');
        this.html = document.querySelector('html');
        this.underlay = document.querySelector('.menu-underlay');

        this.closes = document.querySelectorAll('[data-menu="close"]');

        if (this.menu) {
            this.hamburger = document.querySelector('.hamburgers');
            this.init();
        }
    }

    init() {
        this.hamburger.addEventListener('click', () => {
            this.open ? this.closeMenu() : this.openMenu();
        });
        this.menu.classList.add('init');
        this.underlay.addEventListener('click', () => this.fastCloseMenu());

        for (let i = 0; i < this.closes.length; i++) {
            this.closes[i].addEventListener('click', () => this.fastCloseMenu())
        }
    }

    fastCloseMenu() {
        this.open = false;
        this.hamburger.classList.remove('open');
        this.menu.classList.remove('open');
        this.underlay.classList.remove('is-active');
        setTimeout(() => {
            this.html.classList.remove('is-locked');
        }, 500);
    }


    // Menu animation
    closeMenu() {
        if (this.isAnimate) {
            return false;
        }
        this.startAnimate();
        this.open = false;

        this.hamburger.classList.remove('open');
        this.menu.classList.remove('open');
        setTimeout(() => this.endAnimate(), 500);
        this.html.classList.remove('is-locked');
        this.underlay.classList.remove('is-active');

    }

    openMenu() {
        if (this.isAnimate) {
            return false;
        }
        this.html.classList.add('is-locked');
        this.startAnimate();
        this.open = true;
        this.hamburger.classList.add('open');
        this.menu.classList.add('open');
        this.underlay.classList.add('is-active');

        setTimeout(() => {
            this.endAnimate();
        }, 110)
    }

    startAnimate() {
        this.isAnimate = true;
    }

    endAnimate() {
        this.isAnimate = false;
    }
}
