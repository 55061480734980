import Masonry from 'masonry-layout';
import {LazyImages} from "./modules/lazyImages";
import Swiper from 'swiper/swiper-bundle';
import Menu from "./modules/menu";
import * as $ from 'jquery';
import {FormValidation} from "./modules/FormValidation";
import Accordion from "./modules/Accordion";

const $window = $(window);
const $body = $('body');

new LazyImages($body);

require('lity');

const grid = document.querySelector('.masonry-grid');
if (grid) {
    const msnry = new Masonry(grid, {
        itemSelector: '.tile',
        gutter: 0,
        transitionDuration: 0
    });
}

let suppliersWrappers = document.getElementsByClassName('swiper-holder');

if (suppliersWrappers.length) {
    for (let i = 0; i < suppliersWrappers.length; i++) {
        let supplierWrapper = suppliersWrappers[i];
        let supplier = supplierWrapper.querySelector('.swiper-container-supplier');
        let left = supplierWrapper.querySelector('.supplier-button-prev');
        let right = supplierWrapper.querySelector('.supplier-button-next');

        var supplierSwiper = new Swiper(supplier, {
            slidesPerView: 2,
            loopAdditionalSlides: 10,
            keyboard: {
                enabled: true,
            },
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
            speed: 800,
            loop: true,
            breakpoints: {
                560: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 3,
                },
                1080: {
                    slidesPerView: 6,
                }
            },
            navigation: {
                nextEl: right,
                prevEl: left,
            },
        });
    }
}

/****************************
 *
 * Article search
 *
 ***************************/

const $searchToggle = $('.nav-search__toggle');
if ($searchToggle.length) {
    $searchToggle.on("click", function (e) {
        let $search = $('.nav-search');
        let $menu = $('.menu');
        let $input = $('#search-form input');

        if ($search.hasClass('active')) {
            $search.removeClass('active');

            if ($searchToggle.hasClass('active')) {
                if ($input.val()) {
                    $('#search-form').submit();
                }
            }

            setTimeout(
                function () {
                    $menu.removeClass('hidden');
                    $searchToggle.removeClass('active');
                },
                300);
        } else {
            $searchToggle.addClass('active');
            $search.addClass('active');
            $menu.addClass('hidden');

            setTimeout(function () {
                $input.focus();
            }, 500);

            $input.on('change keyup blur input', function() {
                $input.addClass('autofill-bg');
            });
        }
    });
}

import {FormFilterClass} from "./modules/FormFilterClass";

const searchFilterForm = document.getElementById('ajax-search-form');
const searchResultContainer = document.getElementById('ajax-results');
if (searchFilterForm && searchResultContainer) {
    let searchFilter = null;
    const handleChange = () => $('[data-page]').on('click', e => searchFilter && searchFilter.updatePage($(e.currentTarget).data('page')));

    searchFilter = new FormFilterClass({
        form: searchFilterForm,
        container: searchResultContainer,
        loader: 'results-loader',
        isAjax: true,
        updateState: true,
        AllowStateParams: [
            'q',
            'page'
        ],
        onChange: handleChange
    });

    handleChange();
}


const toggleItems = document.querySelectorAll('[data-action="choose-language"]');

if (toggleItems.length) {
    $(toggleItems).on('click', function () {
        let $this = $(this);

        if ($this.siblings().hasClass('active')) {
            $this.siblings().removeClass('active');

            if ($this.hasClass('active')) {
                $this.removeClass('active');
            } else {
                $this.addClass('active');
            }
        }
    });
}

new Menu();


/****************************
 *
 * Toggle submenu
 *
 ***************************/
let jsMobileSubmenuTrigger = document.getElementsByClassName('js-mobile-submenu-trigger');
if (jsMobileSubmenuTrigger.length) {
    for (let i = 0; i < jsMobileSubmenuTrigger.length; i++) {
        let menuItem = jsMobileSubmenuTrigger[i];
        let subMenu = $(menuItem).closest('.menu-item').find('.submenu-item');
        menuItem.addEventListener('click', function () {
            if (this.classList.contains('is-triggered')) {
                this.classList.remove('is-triggered');
                subMenu.slideUp();
            } else {
                this.classList.add('is-triggered');
                subMenu.slideDown();
            }
        });
    }
}

let productsWrappers = document.getElementsByClassName('products-swiper-holder');

if (productsWrappers.length) {
    for (let i = 0; i < productsWrappers.length; i++) {
        let productsWrapper = productsWrappers[i];
        let product = productsWrapper.querySelector('.swiper-container-products');
        let left = productsWrapper.querySelector('.products-button-prev');
        let right = productsWrapper.querySelector('.products-button-next');

        var productsSwiper = new Swiper(product, {
            slidesPerView: 1,
            loopAdditionalSlides: 10,
            keyboard: {
                enabled: true,
            },
            speed: 800,
            loop: true,
            breakpoints: {
                768: {
                    slidesPerView: 2,
                },
                960: {
                    slidesPerView: 3,
                },
                1280: {
                    slidesPerView: 4,
                }
            },
            navigation: {
                nextEl: right,
                prevEl: left,
            },
        });
    }
}

const axios = require('axios');

$(document).on('click', '[data-popup]', function (e, a) {
    let target = e.target;
    let url = target.dataset.popup;
    var uniWrapper = new Date().valueOf();
    var uniContent = new Date().valueOf() + 'a';
    let body = $('body');

    let $popup =
        `<div class="popup__wrapper" id="${uniWrapper}">
            <div class="popup__underlay"></div>
            <div class="popup bg-l_grey">
                <div class="popup__content" id="${uniContent}">

                </div>
            </div>
        </div>`;
    body.append($popup);
    body.addClass('is-locked');
    let contentWrapper = document.getElementById(uniContent);
    let wrapperWrapper = document.getElementById(uniWrapper);

    wrapperWrapper.querySelector('.popup__underlay').addEventListener('click', close);

    function close() {
        wrapperWrapper.parentNode.removeChild(wrapperWrapper);
        body.removeClass('is-locked');
    }

    function triggerClose() {
        let $close = wrapperWrapper.querySelectorAll('[data-popup-close]');
        if ($close.length) {
            for (let i = 0; i < $close.length; i++) {
                $close[i].addEventListener('click', close);
            }
        }
    }

    axios.post(url).then(function (response) {
        contentWrapper.innerHTML = response.data.view;
        triggerClose();
    })
});


/****************************
 *
 * Form validation
 *
 ***************************/

let forms = document.getElementsByClassName('form');

if (forms.length) {
    for (let i = 0; i < forms.length; i++) {
        let form = forms[i];

        let $from = $(form);
        let t = new FormValidation($from, {
            inputGroup: 'input-group',
            invalidClass: 'is-invalid',
            loading: '<i class="fa fa-spinner fa-spin mx-auto"></i>',
            button: form.querySelector('button'),
            error: 'X',
            onSubmitSuccess: (res) => {
                let beforeElement = $from.find('.before-submit');
                let afterElement = $from.find('.after-submit');

                if (res.hasOwnProperty('success')) {
                    beforeElement.hide();
                    afterElement.show();
                }
            },
            onSubmitFailed: (r) => {
                if (r.hasOwnProperty('errors')) {
                    t.showErrors(r.errors);
                }
                if (r.hasOwnProperty('errorsbag')) {

                }
            },
        });
    }
}


/****************************
 *
 * Accordion toggle
 *
 ***************************/

let accordions = document.getElementsByClassName('accordion');
if (accordions.length) {
    for (let i = 0; i < accordions.length; i++) {
        new Accordion(accordions[i]);
    }
}


let closeAlert = document.getElementsByClassName('alert__close');

if (closeAlert.length) {

    $(closeAlert).on('click', function (e) {
        let $this = $(this);
        $this.closest('.alert').addClass('hide');
    })
}


/****************************
 *
 * Toggle mobile product filter
 *
 ***************************/
let filterToggle = document.querySelectorAll('[data-action="toggle-open"]');

if (filterToggle.length) {
    $(filterToggle).on('click', function () {
        let $this = $(this);
        let filter = $('.col-product-filter');
        let visibleText = $this.find('.filter-title-visible');
        let hiddenText = $this.find('.filter-title-hidden');

        if (!filter.hasClass('active')) {
            visibleText.addClass('hidden');
            hiddenText.removeClass('hidden');
            filter.addClass('active');
        } else {
            hiddenText.addClass('hidden');
            visibleText.removeClass('hidden');
            filter.removeClass('active');
        }
    });
}


